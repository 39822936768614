import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import { selectInputStyles } from 'rapidfab/constants/styles';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'rapidfab/utils/alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  Col,
  FormLabel,
  FormControl,
  FormGroup,
  Dropdown,
  Nav,
  NavItem,
  NavLink,
  Card,
  Row,
  SplitButton,
  ButtonToolbar,
  InputGroup,
} from 'react-bootstrap';

import Loading from 'rapidfab/components/Loading';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import { getShortUUID, extractUuid } from 'rapidfab/utils/uuidUtils';
import { MATERIAL_BATCH_STATUS_MAP, MODELER_STATUS_MAP } from 'rapidfab/mappings';
import Feature from 'rapidfab/components/Feature';
import {
  ACCESS_INFO_ACTION_TYPES,
  FEATURES, MACHINE_TYPE,
  MODELER_TYPE_MANUFACTURERS,
  REACT_SELECT_ACTIONS,
  ROUTES,
} from 'rapidfab/constants';
import {
  modelerResourceType, locationResourceType,
  materialBatchResourceType, materialLotResourceType,
  printerTypeResourceType, printerResourceType, subLocationResourceType,
} from 'rapidfab/types/resources';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow, { RESOURCE_READ_ONLY_VIEW_FIELD_TYPES } from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import ModelerStatusDot from 'rapidfab/components/modelerStatusDot';
import ModelerModalContainer from 'rapidfab/containers/ModelerModalContainer';
import ModelerDeleteModalContainer from 'rapidfab/containers/ModelerDeleteModalContainer';
import DowntimeContainer from 'rapidfab/containers/downtime/DowntimeContainer';
import DisabledByAccessInfoCheck from 'rapidfab/components/DisabledByAccessInfoCheck';
import LastUpdated from 'rapidfab/components/LastUpdated';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import PrinterFormCommandView from 'rapidfab/components/records/PrinterFormCommandView';
import { faBan, faBarChart, faExternalLink, faFeed } from '@fortawesome/free-solid-svg-icons';
import CreatableSelect from 'react-select/creatable';

const styles = {
  spacingTop: { marginTop: '1rem' },
};

const MODALS = {
  ADD_EDIT_MODELER: 'add-edit-modeler',
  DELETE_MODELER: 'delete-modeler',
};

const PRINTER_INFO_TABS = {
  LIVE_DATA: 'live-data',
  DOWNTIME: 'downtime',
};

const PrinterForm = ({
  handleSubmit,
  handleInputChange,
  handleDelete,
  loading,
  location,
  subLocation,
  subLocations,
  locations,
  name,
  printerType,
  printerTypes,
  uuid,
  loadedBatch,
  loadedBatchLot,
  modeler,
  setModelerUri,
  printer,
  submitting,
  modelerData,
  subLocationsFetching,
  handleChangePrinterType,
  setNewPrinterTypeName,
  newPrinterTypeName,
  printerTypeOnTheFlySubmitting,
  printerTypeOnTheFlyFetching,
}) => {
  const [visibleModal, setVisibleModal] = useState(null);
  const [tab, setTab] = useState(PRINTER_INFO_TABS.LIVE_DATA);

  const formattedPrinterTypes = printerTypes.map(printerType => ({
    label: printerType.name,
    value: printerType.uri,
  })).filter(Boolean);

  const onModelerModalOpen = () => {
    if (!printer) {
      Alert.warning(
        <FormattedMessage
          id="toaster.warning.saveBeforeAddingLink"
          defaultMessage="Please save printer before adding printer link"
        />);
      return;
    }
    setVisibleModal(MODALS.ADD_EDIT_MODELER);
  };

  const onModelerSave = modelerUri => {
    // Update printer resource when modeler is created/updated and then close modal
    setModelerUri(modelerUri).then(() => {
      setVisibleModal(null);
    });
  };

  const onModelerModalClose = () => {
    setVisibleModal(null);
  };

  const onModelerDeleteModalOpen = () => {
    setVisibleModal(MODALS.DELETE_MODELER);
  };

  const onModelerDelete = () => {
    // Update printer resource with empty modeler and then close modal
    setModelerUri(null).then(() => {
      setVisibleModal(null);
    });
  };

  const onModelerDeleteClose = () => {
    setVisibleModal(null);
  };

  const onSelectPrinterInfoTab = selectedTab => {
    setTab(selectedTab);
  };

  const handlePrinterTypeChange = (selectedOption, actionMeta, onChange) => {
    // The user would like to create the new manufacturer "on the fly"
    if (actionMeta.action === REACT_SELECT_ACTIONS.CREATE_OPTION) {
      // Set the potential new Printer Type name for the Creation on the Fly
      setNewPrinterTypeName(selectedOption.label);
    }
    const newValue = selectedOption ? selectedOption.value : '';
    return onChange(newValue);
  };

  const selectedOption = formattedPrinterTypes.find(option =>
    option.value === printerType) || null;

  if (loading) {
    return (
      <div style={styles.spacingTop}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div style={styles.spacingTop}>
        <Row>
          <Col xs={12} sm={6}>
            <form onSubmit={handleSubmit}>
              <Card bg="dark">
                <Card.Header className="pd-exp accent">
                  <FormattedMessage
                    id="field.summary"
                    defaultMessage="Summary"
                  />
                </Card.Header>
                <div className="card-body-wrapper-accent">
                  <Card.Body>
                    <div className="clearfix mb15">
                      {printer && printer.uri && (
                        <Feature featureName={FEATURES.MATERIAL_MANAGEMENT}>
                          <Link
                            className="btn btn-info-outline"
                            to={getRouteURI(ROUTES.PRINT_PRINTER_QR, { uuid: printer.uuid }, {}, true)}
                          >
                            <BureauBarcodeIcon
                              className="spacer-right"
                              qrCodeText="Machine QR Code"
                              barcodeText="Machine Barcode"
                            />
                          </Link>
                        </Feature>
                      )}
                      <div className="pull-right">
                        <DisabledByAccessInfoCheck
                          resourceUri={printer && printer.uri}
                          actionType={ACCESS_INFO_ACTION_TYPES.EDIT}
                          renderDisabledPrefix
                        >
                          {({ disabled }) => (
                            <SplitButton
                              id="uxSaveDropdown"
                              type="submit"
                              variant="success"
                              size="sm"
                              title={submitting ? <Loading /> : <SaveButtonTitle />}
                              disabled={disabled}
                            >
                              <Dropdown.Item eventKey={1} onClick={handleDelete} disabled={!uuid}>
                                <FontAwesomeIcon icon={faBan} />{' '}
                                <FormattedMessage id="button.delete" defaultMessage="Delete" />
                              </Dropdown.Item>
                            </SplitButton>
                          )}
                        </DisabledByAccessInfoCheck>
                      </div>
                    </div>

                    <FormGroup className="form-group" controlId="uxName">
                      <FormLabel>
                        <FormattedMessage
                          id="field.name"
                          defaultMessage="Name"
                        />
                        : *
                      </FormLabel>
                      <FormControl
                        name="name"
                        onChange={handleInputChange}
                        required
                        type="text"
                        value={name}
                      />
                    </FormGroup>

                    <FormGroup className="form-group" controlId="uxPrinterFormType">
                      <FormLabel>
                        <UseNonMfgLanguageFeature
                          mfgLanguageComponent={(
                            <FormattedMessage
                              id="field.printerType"
                              defaultMessage="Printer Type"
                            />
                          )}
                          nonMfgLanguageComponent={(
                            <FormattedMessage
                              id="mfg.printerType.productionDeviceType"
                              defaultMessage="Production Device Type"
                            />
                          )}
                        />
                        : *
                        <Link
                          target="_blank"
                          className="spacer-left"
                          rel="noopener noreferrer"
                          to={getRouteURI(ROUTES.PRINTER_TYPES, null, { uuid: extractUuid(printerType) }, true)}
                        >
                          <FontAwesomeIcon icon={faExternalLink} />
                        </Link>
                      </FormLabel>

                      <InputGroup>
                        <CreatableSelect
                          key={newPrinterTypeName}
                          styles={selectInputStyles}
                          isLoading={printerTypeOnTheFlyFetching || printerTypeOnTheFlySubmitting}
                          placeholder="Select Printer Type"
                          isDisabled={printerTypeOnTheFlySubmitting || printerTypeOnTheFlyFetching}
                          components={{ LoadingIndicator: () => (<Loading inline className="spacer-right" />) }}
                          value={selectedOption}
                          options={formattedPrinterTypes}
                          required
                          onChange={(value, actionMeta) =>
                            handlePrinterTypeChange(value, actionMeta, handleChangePrinterType)}
                          isClearable
                        />
                      </InputGroup>

                    </FormGroup>

                    <FormGroup className="form-group" controlId="uxLocation">
                      <FormLabel>
                        <FormattedMessage
                          id="field.location"
                          defaultMessage="Location"
                        />
                        : *
                      </FormLabel>
                      <FormControl
                        name="location"
                        as="select"
                        onChange={handleInputChange}
                        required
                        value={location}
                      >
                        {locations.map(loc => (
                          <option key={loc.uri} value={loc.uri}>
                            {loc.name}
                          </option>
                        ))}
                      </FormControl>
                    </FormGroup>

                    <FormGroup className="form-group" controlId="uxSubLocation">
                      <FormLabel>
                        <FormattedMessage
                          id="field.sub_location"
                          defaultMessage="Sub-Location"
                        />
                        : *
                        {subLocationsFetching && <Loading inline className="spacer-left" />}
                      </FormLabel>
                      <FormControl
                        name="sub_location"
                        as="select"
                        disabled={!location || subLocationsFetching}
                        onChange={handleInputChange}
                        required
                        value={subLocation}
                      >
                        <option key="placeholder" value="" selected disabled>
                          {!location ? 'Select a Location' : 'Select a Sub-Location'}
                        </option>
                        {subLocations.map(subLocation => (
                          <option key={subLocation.uri} value={subLocation.uri}>
                            {subLocation.name}
                          </option>
                        ))}
                      </FormControl>
                    </FormGroup>
                  </Card.Body>
                </div>
              </Card>
            </form>
            {modelerData.type?.manufacturer === MODELER_TYPE_MANUFACTURERS.PHOTOCENTRIC_LTD &&
              <PrinterFormCommandView printer={printer} />}
            <LastUpdated resource={printer} />
          </Col>

          {printer && printer.uri && (
            <Col xs={12} sm={6}>
              <Nav
                variant="tabs"
                activeKey={tab}
                onSelect={onSelectPrinterInfoTab}
              >
                <NavItem>
                  <NavLink eventKey={PRINTER_INFO_TABS.LIVE_DATA}>
                    <FontAwesomeIcon icon={faFeed} />
                    {' '}
                    <FormattedMessage
                      id="liveData"
                      defaultMessage="Live Data"
                    />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink eventKey={PRINTER_INFO_TABS.DOWNTIME}>
                    <FontAwesomeIcon icon={faBarChart} />
                    <UseNonMfgLanguageFeature
                      mfgLanguageComponent=" Printer Downtime"
                      nonMfgLanguageComponent=" Production Device Downtime"
                    />
                  </NavLink>
                </NavItem>
              </Nav>
              <Card bg="dark">
                <div className="card-body-wrapper">
                  <Card.Body>
                    {tab === PRINTER_INFO_TABS.LIVE_DATA && (
                      <>
                        <ResourceReadOnlyView
                          placeholder={(
                            <div>
                              <FormattedMessage
                                id="establishDataConnectionMachineAnalytics"
                                defaultMessage="Establish a data connection to see machine analytics details."
                              />
                              <Button
                                className="flexed-pull-right btn-sm btn-primary ms-1"
                                onClick={onModelerModalOpen}
                              >
                                Add
                              </Button>
                            </div>
                          )}
                          entity={modeler}
                        >
                          <ButtonToolbar className="pull-right">
                            <Button
                              className="btn-sm btn-primary"
                              onClick={onModelerModalOpen}
                            >
                              Edit
                            </Button>
                            <Button
                              className="btn-sm btn-danger ml5"
                              onClick={onModelerDeleteModalOpen}
                            >
                              Delete
                            </Button>
                          </ButtonToolbar>
                          <ResourceReadOnlyViewRow name="name" />
                          <ResourceReadOnlyViewRow
                            name="status"
                            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                            customValue={(
                              modeler && modeler.status && (
                                <>
                                  <ModelerStatusDot modeler={modeler} />
                              &nbsp;
                                  <FormattedMessage {...MODELER_STATUS_MAP[modeler.status]} />
                                </>
                              )
                            )}
                          />
                          <ResourceReadOnlyViewRow
                            name="latest_upload"
                            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATETIME}
                          />
                          <ResourceReadOnlyViewRow
                            name="ip_address"
                            label="IP Address"
                          />
                          <ResourceReadOnlyViewRow name="serial_number" />
                        </ResourceReadOnlyView>
                        <Feature featureName={FEATURES.MATERIAL_MANAGEMENT}>
                          <hr />
                          <ResourceReadOnlyView
                            title="Currently Loaded Material Batch Summary"
                            placeholder="No batch is currently loaded in this machine."
                            entity={loadedBatch}
                          >
                            <ResourceReadOnlyViewRow
                              name="uri"
                              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                              label="Batch"
                              customValue={(
                                loadedBatch && (
                                  <Link
                                    to={getRouteURI(ROUTES.MATERIAL_BATCH,
                                      { uuid: extractUuid(loadedBatch.uri) }, {}, true)}
                                  >
                                    {getShortUUID(loadedBatch.uri)} {loadedBatchLot && `(${loadedBatchLot.name})`}
                                  </Link>
                                )
                              )}
                            />
                            <ResourceReadOnlyViewRow
                              name="is_initial_batch"
                              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                              label="Initial Batch"
                              customValue={(
                                <>
                                  {loadedBatch && loadedBatch.is_initial_batch && 'Yes'}
                                  {loadedBatch && !loadedBatch.is_initial_batch && (
                                    <Link
                                      to={getRouteURI(
                                        ROUTES.MATERIAL_BATCH,
                                        { uuid: extractUuid(loadedBatch.initial_batch) }, {}, true,
                                      )}
                                    >
                                      {getShortUUID(loadedBatch.initial_batch)} {loadedBatchLot && `(${loadedBatchLot.name})`}
                                    </Link>
                                  )}
                                </>
                              )}
                            />
                            <ResourceReadOnlyViewRow
                              name="quantity"
                              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                              customValue={loadedBatch && `${loadedBatch.quantity} ${loadedBatch.units}`}
                            />
                            <ResourceReadOnlyViewRow
                              name="containers"
                              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                              customValue={loadedBatch && loadedBatch.containers.length}
                            />
                            <ResourceReadOnlyViewRow name="usage_cycles" />
                            <ResourceReadOnlyViewRow
                              name="location_name"
                              label="Location"
                            />
                            <ResourceReadOnlyViewRow
                              name="material_name"
                              label="Material"
                            />
                            <ResourceReadOnlyViewRow
                              name="status"
                              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                              customValue={
                                loadedBatch
                            && <FormattedMessage {...MATERIAL_BATCH_STATUS_MAP[loadedBatch.status]} />
                              }
                            />
                            <ResourceReadOnlyViewRow
                              name="updated"
                              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATETIME}
                            />
                          </ResourceReadOnlyView>
                        </Feature>
                      </>
                    )}
                    {tab === PRINTER_INFO_TABS.DOWNTIME && (
                      <DowntimeContainer machineType={MACHINE_TYPE.PRINTER} machineUri={printer.uri} />
                    )}
                  </Card.Body>
                </div>
              </Card>
            </Col>
          )}
        </Row>
      </div>
      {visibleModal === MODALS.ADD_EDIT_MODELER && (
        <ModelerModalContainer
          uuid={modeler && modeler.uuid}
          onClose={onModelerModalClose}
          onSave={onModelerSave}
        />
      )}
      {visibleModal === MODALS.DELETE_MODELER && (
        <ModelerDeleteModalContainer
          uuid={modeler && modeler.uuid}
          onClose={onModelerDeleteClose}
          onDelete={onModelerDelete}
        />
      )}
    </>
  );
};

PrinterForm.defaultProps = {
  location: '',
  subLocation: '',
  printerType: '',
  uuid: null,
  loadedBatch: null,
  loadedBatchLot: null,
  modeler: null,
  printer: null,
  newPrinterTypeName: null,
  printerTypeOnTheFlySubmitting: false,
  printerTypeOnTheFlyFetching: false,
};

PrinterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.string,
  subLocation: PropTypes.string,
  locations: PropTypes.arrayOf(locationResourceType).isRequired,
  subLocations: PropTypes.arrayOf(subLocationResourceType).isRequired,
  subLocationsFetching: PropTypes.bool.isRequired,
  printer: printerResourceType,
  modeler: modelerResourceType,
  name: PropTypes.string.isRequired,
  printerType: PropTypes.string,
  printerTypes: PropTypes.arrayOf(printerTypeResourceType).isRequired,
  uuid: PropTypes.string,
  loadedBatch: materialBatchResourceType,
  loadedBatchLot: materialLotResourceType,
  setModelerUri: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  modelerData: PropTypes.shape({
    type: PropTypes.shape({
      manufacturer: PropTypes.string,
    }),
  }).isRequired,
  handleChangePrinterType: PropTypes.func.isRequired,
  setNewPrinterTypeName: PropTypes.func.isRequired,
  newPrinterTypeName: PropTypes.oneOfType([PropTypes.string, null]),
  printerTypeOnTheFlySubmitting: PropTypes.bool,
  printerTypeOnTheFlyFetching: PropTypes.bool,
};

export default PrinterForm;
